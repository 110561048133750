"use client";

import Image from "next/image";

import Bali from "@/public/png/home/popular-destinations/bali.png";
import Dubai from "@/public/png/home/popular-destinations/dubai.png";
import Maldives from "@/public/png/home/popular-destinations/maldives.png";
import Singapore from "@/public/png/home/popular-destinations/singapore.png";
import Thailand from "@/public/png/home/popular-destinations/thailand.png";
import Malaysia from "@/public/png/home/popular-destinations/malaysia.png";

import Andaman from "@/public/png/home/popular-destinations/andaman.jpg";
import Srilanka from "@/public/png/home/popular-destinations/srilanka.jpg";
import Vietnam from "@/public/png/home/popular-destinations/vietnam.jpg";
import Hongkong from "@/public/png/home/popular-destinations/hong-kong.png";

import ViewMoreIcon from "@/public/svg/home/phone-call.svg";
import PaginationLeft from "@/public/svg/paginarition-arrow-left.svg";
import PaginationRight from "@/public/svg/paginarition-arrow-right.svg";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "@/styles/common/swiper.scss";
import "@/styles/common/typo.scss";

import Title from "@/components/title/Title";
import Heading from "@/components/heading/heading";

import "./destinationPriceCard.scss";

const Packages = [
    {
        name: "Bali",
        picture: Bali,
        note: "Starting from ₹25,000",
        path: "bali",
        getInTouch: true,
    },
    {
        name: "Dubai",
        picture: Dubai,
        note: "Starting from ₹45,000",
        path: "dubai",
        getInTouch: true,
    },
    {
        name: "Maldives",
        picture: Maldives,
        note: "Starting from ₹41,000",
        path: "maldives",
        getInTouch: true,
    },
    {
        name: "Singapore",
        picture: Singapore,
        note: "Starting from ₹40,000",
        path: "singapore",
        getInTouch: false,
    },
    {
        name: "Thailand",
        picture: Thailand,
        note: "Starting from ₹18,000",
        path: "thailand",
        getInTouch: true,
    },
    {
        name: "Malaysia",
        picture: Malaysia,
        note: "Starting from ₹29,000",
        path: "malaysia",
        getInTouch: true,
    },
    {
        name: "Hongkong",
        picture: Hongkong,
        note: "Starting from ₹55,499",
        path: "hongkong",
        getInTouch: true,
    },
    {
        name: "Andaman",
        picture: Andaman,
        note: "Starting from ₹16,000",
        path: "andaman",
        getInTouch: true,
    },
    {
        name: "Srilanka",
        picture: Srilanka,
        note: "Starting from ₹23,000",
        path: "srilanka",
        getInTouch: false,
    },
    {
        name: "Vietnam",
        picture: Vietnam,
        note: "Starting from ₹18,000",
        path: "vietnam",
        getInTouch: false,
    },
];

const DestinationPriceCard = () => {
    return (
        <section className="packages-based-section py-14 md:py-20 mb-12">
            <div className="packages-based-container container px-4 flex flex-col mx-auto gap-6 sm:gap-14">
                <Title title="Tailored Experiences" />

                <div className="packages-based-slider">
                    <div className="packages-swiper flex justify-center flex-wrap gap-6">
                        {Packages.map((p, index) => {
                            return (
                                <div
                                    key={index}
                                    className="packages-slide card-width"
                                >
                                    <a
                                        href={p.path}
                                        className="inline-flex flex-col gap-6 p-6"
                                    >
                                        <figure>
                                            <Image
                                                loading="lazy"
                                                src={p.picture}
                                                alt={p.name}
                                            />
                                        </figure>
                                        <div className="packages-info flex flex-col gap-2 pt-6">
                                            <Heading as="h6">{p.name}</Heading>
                                            <span className="font-light">
                                                {p.note}
                                            </span>
                                        </div>
                                    </a>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default DestinationPriceCard;
